
const apiUrl = process.env.REACT_APP_API_URL;

const GET_RESTAURANT = "restaurants/GET_RESTAURANT";
const GET_ONLINE_STATUS = "restaurants/GET_ONLINE_STATUS";


const getRestaurant = (restaurant) => ({
    type: GET_RESTAURANT,
    restaurant
})

export const getOnlineStatus = (status) => ({
    type: GET_ONLINE_STATUS,
    status
})


export const getRestaurantThunk = (id) => async (dispatch) => {
    const response = await fetch(`https://hutaoadmin.onrender.com/api/restaurants/${id}`, {
        // headers: { 
        //     'Content-Type': 'application/json',
        //     credentials: 'include',
        // },

        // headers: { 
        // 'Content-Type': 'application/json',
        // credentials: 'include',
        // },
    })

    if (response.ok) {
        const data = await response.json();
        dispatch(getRestaurant(data))
    }
}

export const getOnlineStatusThunk = (id) => async (dispatch) => {
    const response = await fetch(`https://hutaoadmin.onrender.com/api/restaurants/${id}/online-status`, {
        // headers: {
        // 'Content-Type': 'application/json',
        // credentials: 'include',
        // },
    })

    if (response.ok) {
        const data = await response.json();
        dispatch(getOnlineStatus(data))
        return data;
    }

}


const initialState = { restaurant: null }

const restaurantReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RESTAURANT:
            return { ...state, restaurant: action.restaurant }
        case GET_ONLINE_STATUS:
            return { ...state, onlineStatus: action.status }
        default:
            return state;
    }
}

export default restaurantReducer;
