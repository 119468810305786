// import { getToken } from "./utlits";
const apiUrl = process.env.REACT_APP_API_URL;

const PLACE_ORDER = "cart/PLACE_ORDER";


const placeOrder = (cart) => ({
    type: PLACE_ORDER,
    cart
})


export const placeOrderThunkPayInStore = (id, paymentOption, name, phone, tip, address, cartItems, coupon, freeItem, payment_id) => async (dispatch) => {

    const response = await fetch(`https://hutaoadmin.onrender.com/api/orders/${id}`, {
        method: 'POST',
        body: JSON.stringify({ paymentOption, name, phone, tip, address, cartItems, coupon, freeItem, payment_id }),
        headers: {
            'Content-Type': 'application/json',
            credentials: 'include',
        },
        credentials: 'include',

    })

    if (response.ok) {
        const data = await response.json();
        dispatch(placeOrder(data))
        localStorage.removeItem('cart')
        localStorage.removeItem('freeItem')

    }
}


const initialState = {
    cart: [],
    order: {}
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case PLACE_ORDER:
            return {
                ...state,
                order: action.cart,
                cart: []
            };
        default:
            return state;
    }
};

export default cartReducer;
