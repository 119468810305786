import Lottie from "react-lottie-player"
import lottieJson from "../assets/json/order-sucess.json"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchOrderDataThunk } from "../store/stripe";
import { clearCoupon} from "../store/coupons"
import Header from "../components/Header"


const CommingSoon = () => {
  const dispatch = useDispatch()
  const heartRef = useRef(null)
  const order = useSelector((state) => state.cart.order);
  const [searchParams] = useSearchParams();
  const paymentIntentId = searchParams.get("payment_intent");
  const [orderId, setOrderId] = useState(order?.order_id || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    const fetchOrderId = async () => {
      if (!paymentIntentId || attempts >= 3) return;

      try {
        const order = await dispatch(fetchOrderDataThunk(paymentIntentId));
        if (order) {
          setOrderId(order.order_id);
          localStorage.removeItem('cart');
          localStorage.removeItem('freeItem');
          localStorage.removeItem('rewardItem')
          dispatch(clearCoupon());

        } else {
          throw new Error("Order ID not found");
        }
      } catch (err) {
        console.error("❌ Error fetching order ID:", err);
        setAttempts(prev => prev + 1);
        if (attempts >= 2) setError(true);
      }
      setLoading(false);
    };

    if (attempts < 3) {
      const timer = setTimeout(fetchOrderId, 3000);
      return () => clearTimeout(timer);
    }
}, [paymentIntentId, attempts, dispatch]);

  return (
    <>
    <Header />
      <Toaster className='mt-5' position="bottom-right" reverseOrder={true} />
      <div className=" page-content bg-white" style={{marginTop:'100px'}}>
        <div className="coming-wrapper overflow-hidden">
          <div className="mt-5 container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="inner-content text-center">
                  <h3 className="coming-head"> Order#: {orderId || (error ? "Order Not Found" : "Processing...")}</h3>
                  <p className="coming-para">
                    {error ? "❌ Order not found. Please contact the restaurant immediately!" : "Thank You! You will receive an SMS shortly with your order updates!"}
                    <br />
                    {orderId && <Link to={`/order/${orderId}/${3}`}>Click Here To View Order Receipt</Link>}
                  </p>
                  <Lottie
                    className="coming-media"
                    loop
                    animationData={lottieJson}
                    play
                  />
                </div>
                <div className="middle-content">
                </div>
                <div className="coming-footer text-center">
                  <p>
                    © Copyrights{" "}
                    <span
                      className="heart"
                      ref={heartRef}
                      onClick={() => {
                        heartRef.current?.classList.toggle("heart-blast")
                      }}
                    ></span>{" "}
                    by{" "}
                    <Link to="/" target="_blank">
                      Rainbow Sushi
                    </Link>{" "}
                    | 2025 All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommingSoon
