import React, { useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import { useDispatch, useSelector } from "react-redux";
import { createCheckoutSessionThunk } from "../store/stripe";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./StripeCheckoutForm";


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
// const stripePromise = loadStripe("pk_test_51N1jsSIzDtG53Sp0X5LEbfj3PLSnsTabqURdXKBEbHqHbr4gG48TacEO4aFcWTd6t4BOFjv2uQaJDjUVpijOjns700cXVFzGvo", { 
  // stripeAccount: 'acct_1Oy3kvGtGhbWLvKX'
// });
const stripePromise = loadStripe("pk_live_51N1jsSIzDtG53Sp0IYroac8Yq8aSbX7PQgISERDGaQNviBMnz4SGJ4RhjZws64QrELl0aJoWqtyYe5W3Ud6bqhmV00sR5crhK1", { 
  stripeAccount: 'acct_1Oy3kvGtGhbWLvKX'
});

const CheckoutApp = ({tip, restaurantId, name, phone, cartItems, coupon, freeItem, address}) => {
  const dispatch = useDispatch();
  const clientSecret = useSelector((state) => state?.stripe?.data?.clientSecret);

  useEffect(() => {
    dispatch(createCheckoutSessionThunk(tip, restaurantId, cartItems, coupon, address, name, phone, freeItem))

  }, [dispatch]);

  const appearance = {
    theme: 'flat',
  };
  const options = {
    clientSecret,
    appearance,
  };


  return (
    <div >
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}

export default CheckoutApp;
